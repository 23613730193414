
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { Field } from "vee-validate";
import { useRoute } from "vue-router";
import * as Yup from "yup";

import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";

export default defineComponent({
  name: "kt-drawer-employee-list",

  components: { Field },
  props: {
    data: { type: Object },
  },

  data() {
    return {
      api_url: process.env.VUE_APP_API_URL,
      formData: {
        active_status:"",
        father_name: "",
        mother_name: "",
        name: "",
        designation: "",
        dob: "",
        nid: "",
        passport: "",
        driving_license: "",
        mobile: "",
        email: "",
        gender: "",
        religion: "",
        present_address: "",
        present_district: "",
        permanent_address: "",
        permanent_district: "",
        img: "",
      },
      employee_img: "",
      load: false,
      empDistricts: [],
      tranche: [],
      institute: [],
      url: "",
      empDesignation: [],
      loading: false,
    };
  },
  async created() {
    this.load = true;
    await this.getEmployeeDesignation();
    await this.getDistrict();
    this.emitter.on("edit-employee-data", async (data) => {
      this.load = true;
      this.url = "";
      this.formData = data.employee;
      this.employee_img = data.attach_file_info;
      setTimeout(() => {
        this.load = false;
      }, 2000);
    });
  },

  methods: {
    async getEmployeeDesignation() {
      await ApiService.get("entity_type_role/role/allroles?entity_type_id=4")
        .then((response) => {
          this.empDesignation = response.data.data.role;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    updatePhoto(e) {
      this.formData.img = e.target.files[0];
      this.url = URL.createObjectURL(this.formData.img);
    },
    async getDistrict() {
      await ApiService.get("configurations/geo_district/list")
        .then((response) => {
          this.empDistricts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async formSubmit() {
      let formData = new FormData();

      formData.set("employee_image", this.employee_img);

      for (var key in this.formData) {
        if (this.formData[key]) {
          formData.set(key, this.formData[key]);
        }
      }
     
      this.loading = true;
      await ApiService.post("configurations/employee/update", formData)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            if (response.data.status == 'success') {
              Swal.fire({
                title: "Success!",
                text: "Employee Updated Successfully.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              }).then(() => {
                DrawerComponent?.hideAll();
                this.emitter.emit("employee-data-update-done");
                // this.formData = {
                //   id: "",
                //   entity_info_id: "",
                //   tranche_id: "",
                //   target_year_id: "",
                //   target_year: "",
                //   target_trainee_number: "",
                // };
              });
            } else {
              Swal.fire({
                title: "Please check all the required field",
                html: response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Close",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
              });
            }

          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.data,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
  },
  setup() {
    const AssociationSchema = Yup.object().shape({});
    const route = useRoute();
    const instituteInfoId = route.params.id;
    return {
      AssociationSchema,
      instituteInfoId,
    };
  },
});
