
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { Field } from "vee-validate";

export default defineComponent({
  name: "kt-drawer-association-contract",
  components: { Field },
  props: {
    data: { type: Object },
  },
  setup() {
    const entityInfoID = 5;
    const formData = ref({
      id: "",
      target_trainee: "",
    });
    const formRef = ref<null | HTMLFormElement>(null);
    const addModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const update = ref<boolean>(false);
    const rules = ref({
      entity_name: [
        {
          required: true,
          message: "Entity Name is required",
          trigger: "change",
        },
      ],
      entity_short_name: [
        {
          required: true,
          message: "Entity Short Name is required",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "Email is required",
          trigger: "change",
        },
      ],
      telephone: [
        {
          required: true,
          message: "Telephone is required",
          trigger: "change",
        },
      ],
      registration_number: [
        {
          required: true,
          message: "Registration Number is required",
          trigger: "change",
        },
      ],
      registration_authority: [
        {
          required: true,
          message: "Registration Authority is required",
          trigger: "change",
        },
      ],
      postcode: [
        {
          required: true,
          message: "Postcode is required",
          trigger: "change",
        },
      ],
      sub_district: [
        {
          required: true,
          message: "Sub District is required",
          trigger: "change",
        },
      ],
      district: [
        {
          required: true,
          message: "District is required",
          trigger: "change",
        },
      ],
      division: [
        {
          required: true,
          message: "Division is required",
          trigger: "change",
        },
      ],
    });
    const { bus } = useBus();

    bus.on("edit-modal-data", (data) => {
      update.value = true;
      formData.value = data;
    });

    bus.on("add-modal-data", () => {
      update.value = false;
      formData.value = {
        id: "",
        target_trainee: "",
      };
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          const action = update.value ? "update" : "post";
          const url = update.value
            ? "entity/infos/" + `${formData?.value?.id}`
            : "entity/infos";

          await ApiService[action](url, formData.value)
            .then((response) => {
              loading.value = false;
              bus.emit("role-updated", true);
              if (response.status == 200) {
                Swal.fire({
                  text: response.data.message,
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  hideModal(addModalRef.value);
                });
              } else {
                let err = "";
                for (const field of Object.keys(response.data.errors)) {
                  err += response.data.errors[field][0] + "<br>";
                }
                Swal.fire({
                  html: err,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Close",
                  customClass: {
                    confirmButton: "btn btn-danger",
                  },
                });
              }
            })
            .catch(({ response }) => {
              loading.value = false;
              console.log(response);
            });
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addModalRef,
      entityInfoID,
    };
  },
  data() {
    return {
      drawer: {},
      load: false,
      dialogImageUrl: "",
      imageUrl: "",
      dialogVisible: false,
      entityInfos: [],
      entityTypes: [],
      avatarFiles: [],
      fileList: [
        {
          name: "attachment.jpeg",
          url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        },
        {
          name: "attachment2.jpeg",
          url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        },
      ],
    };
  },
  async created() {
    await this.getTypes();
    await this.getEntityInfos();
  },
  methods: {
    clearAvatar() {
      this.imageUrl = "";
    },
    handleChange(file, fileList) {
      console.log(file);
      console.log(fileList);
      if (fileList.length > 0) {
        this.imageUrl = URL.createObjectURL(file.raw);
      }
    },
    startLoader() {
      this.load = true;
    },
    handleAvatarSuccess(res, file) {
      this.load = false;
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        Swal.fire({
          text: `Avatar picture must be JPG format!`,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Close",
          customClass: {
            confirmButton: "btn btn-danger",
          },
        });
      }
      if (!isLt2M) {
        Swal.fire({
          text: `Avatar picture size can not exceed 2MB!`,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Close",
          customClass: {
            confirmButton: "btn btn-danger",
          },
        });
      }
      return isJPG && isLt2M;
    },
    handleRemove(file, fileList) {},
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      Swal.fire({
        text: `The limit is 3, you selected ${
          files.length
        } files this time, add up to ${files.length + fileList.length} totally`,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Close",
        customClass: {
          confirmButton: "btn btn-danger",
        },
      });
    },
    beforeRemove(file, fileList) {
      // return Swal.fire(
      //   {
      //     text: `Remove ${file.name} ?`,
      //     icon: "info",
      //     confirmButtonText: "Remove",
      //     cancelButtonText: "Cancel",
      //     customClass: {
      //       confirmButton: "btn btn-danger",
      //       cancelButton: "btn btn-primary",
      //     },
      //     showCancelButton: true,
      //   },
      //   function (isConfirm) {
      //     if (isConfirm) {
      //       Swal(
      //         "Deleted!",
      //         "Your imaginary file has been deleted.",
      //         "success"
      //       );
      //     } else {
      //       Swal("Cancelled", "Your imaginary file is safe :)", "error");
      //     }
      //   }
      // ).then((result) => {
      //   return result.isConfirmed;
      // });
      return confirm(`Cancel the transfert of ${file.name} ?`);
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    handlePicturePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
});
