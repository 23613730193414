
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
export default defineComponent({
  name: "kt-drawer-target-change-summery",
  components: {},
  props: {
    data: { type: Object },
  },
  data() {
    return {
      formData: {
        id: "",
        entity_info_id: "",
        tranche_id: "",
        target_year_id: "",
        target_year_from: "",
        target_year_to: "",
        target_trainee_number: "",
      },
      updateLog: [],
      tableColumn: [
        {
          name: "Tranche",
          key: "tranche_id",
        },
        {
          name: "Association",
          key: "entity_info_id",
        },
        {
          name: "Target Year Id",
          key: "target_year_id",
        },
        {
          name: "Target Year From",
          key: "target_year_from",
        },
        {
          name: "Target Year To",
          key: "target_year_to",
        },
        {
          name: "Trainee Number",
          key: "target_trainee_number",
        },
      ],
      load: false,
      showChange: false,
    };
  },
  async created() {
    this.emitter.on("view-modal-data", async (data) => {
      this.formData = data;
      await this.getUpdateLog();
      await this.getTargetInfo();
    });

  },
  methods: {
    async getTargetInfo() {
      this.load = true;
      await ApiService.get("entity/target/show/" + this.formData.id)
        .then((response) => {
          this.load = false;
          this.formData = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getUpdateLog() {
      this.load = true;
      await ApiService.get("entity/target/update_log/" + this.formData.id)
        .then((response) => {
          this.load = false;
          this.updateLog = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    viewChange() {
      if (this.showChange) {
        this.showChange = false;
      } else {
        this.showChange = true;
      }
    },
  },
});
