
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useBus } from "../../../../bus";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import { VueCookieNext } from "vue-cookie-next";
export default defineComponent({
  name: "kt-drawer-target-list",
  components: {},
  props: {
    data: { type: Object },
  },

  data() {
    return {
      loading: false,
      formData: {
        id: "",
        entity_info_id: "",
        tranche_id: "",
        target_year_id: "",
        target_year_from: "",
        target_year_to: "",
        target_trainee_number: "",
        user_id: "",
      },
      tranche: [],
      componentKey: 0,
      fromYear: [] as any,
      toYear: [] as any,

      association: [],
      ttt: [],
      targetYears: [],

      load: false,
    };
  },
  async created() {
    this.load = true;
    this.emitter.on("edit-modal-data", async (data) => {
      this.formData = data;
      await this.targetLog();
      // this.componentKey += 1;
    });
    await this.associationList();
    await this.getTranche();
    await this.getTargetYear();
    await this.getYear();
  },
  methods: {
    async getYear() {
      let currentYear = new Date().getFullYear();
      let CurrentYearTo = new Date().getFullYear();
      for (let i = 20; i > 0; i--) {
        this.fromYear.push(currentYear);
        currentYear--;
      }
      for (let i = 0; i < 20; i++) {
        this.toYear.push(CurrentYearTo);
        CurrentYearTo++;
      }
    },
    async formSubmit() {
      let user_id = VueCookieNext.getCookie('_seip_user');
      this.formData.user_id = user_id.id;
      let data = {
        target: this.formData,
      };

      this.loading = true;
      await ApiService.post("entity/target/update", data)
        .then((response) => {
          DrawerComponent?.hideAll();
          this.loading = false;
          if (response.status == 200) {
            Swal.fire({
              title: "Success!",
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
            
              // this.formData = {
              //   id: "",
              //   entity_info_id: "",
              //   tranche_id: "",
              //   target_year_id: "",
              //   target_year: "",
              //   target_trainee_number: "",
              // };
            });
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },

    async associationList() {
      let entity_id = "";
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      await ApiService.get("entity/list?entity_id=" + entity_id)
        .then((response) => {
          this.association = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async targetLog() {
      await ApiService.get("entity/target/edit/" + this.formData.id)
        .then((response) => {
          this.load = false;
          if (response.data.data) {
            this.formData = response.data.data;
          }

          console.log(this.formData);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranche() {
      await ApiService.get("configurations/tranche/list")
        .then((response) => {
          this.tranche = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTargetYear() {
      await ApiService.get("configurations/target_year/list")
        .then((response) => {
          this.targetYears = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const TargetListSchema = Yup.object().shape({
      entity_info_id: Yup.string().required().label("Association"),
      tranche_id: Yup.string().required().label("Tranche"),
      target_year_id: Yup.string().required().label("Target Year Id"),
      target_year: Yup.string().required().label("Target Year"),
      target_trainee_number: Yup.string()
        .required()
        .label("Target Trainee Number"),
    });
    return {
      TargetListSchema,
    };
  },
});
