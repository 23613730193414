
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { useBus } from '../../../../bus';
import { Field } from 'vee-validate';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';

export default defineComponent({
  name: 'kt-drawer-association-contract',
  components: { Field },
  props: {
    data: { type: Object },
  },
  setup() {
    const entityInfoID = 5;
    const formData = ref({
      id: '',
      entity_info_id: '',
      tranche_id: '',
      contract_number: '',
      contract_name: '',
      sign_date: '',
      start: '',
      end: '',
      target: '',
      amount: '',
      contribution_percentage: '',
      adjustable_advance_amount: '',
      min_age: '',
      max_age: '',
      age_grace_in_month: '',
      active_status: '',
      status_remarks: '',
    });
    const formRef = ref<null | HTMLFormElement>(null);
    const addModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const update = ref<boolean>(false);
    const rules = ref({
      entity_name: [
        {
          required: true,
          message: 'Entity Name is required',
          trigger: 'change',
        },
      ],
      entity_short_name: [
        {
          required: true,
          message: 'Entity Short Name is required',
          trigger: 'change',
        },
      ],
      email: [
        {
          required: true,
          message: 'Email is required',
          trigger: 'change',
        },
      ],
      telephone: [
        {
          required: true,
          message: 'Telephone is required',
          trigger: 'change',
        },
      ],
      registration_number: [
        {
          required: true,
          message: 'Registration Number is required',
          trigger: 'change',
        },
      ],
      registration_authority: [
        {
          required: true,
          message: 'Registration Authority is required',
          trigger: 'change',
        },
      ],
      postcode: [
        {
          required: true,
          message: 'Postcode is required',
          trigger: 'change',
        },
      ],
      sub_district: [
        {
          required: true,
          message: 'Sub District is required',
          trigger: 'change',
        },
      ],
      district: [
        {
          required: true,
          message: 'District is required',
          trigger: 'change',
        },
      ],
      division: [
        {
          required: true,
          message: 'Division is required',
          trigger: 'change',
        },
      ],
    });
    const { bus } = useBus();

    bus.on('edit-modal-data-view', (data) => {
      update.value = true;
      formData.value = data;
    });

    bus.on('add-modal-data', () => {
      update.value = false;
      formData.value = {
        id: '',
        entity_info_id: '',
        tranche_id: '',
        contract_number: '',
        contract_name: '',
        sign_date: '',
        start: '',
        end: '',
        target: '',
        amount: '',
        contribution_percentage: '',
        adjustable_advance_amount: '',
        min_age: '',
        max_age: '',
        age_grace_in_month: '',
        active_status: '',
        status_remarks: '',
      };
    });

    return {
      formData,
      rules,

      formRef,
      loading,
      addModalRef,
      entityInfoID,
    };
  },
  data() {
    return {
      contract: {
        id: '',
        entity_info_id: '',
        tranche_id: '',
        contract_number: '',
        contract_name: '',
        sign_date: '',
        start: '',
        end: '',
        target: '',
        amount: '',
        contribution_percentage: '',
        adjustable_advance_amount: '',
        min_age: '',
        max_age: '',
        age_grace_in_month: '',
        active_status: '',
        status_remarks: '',
      },
      drawer: {},
      load: false,
      dialogImageUrl: '',
      imageUrl: '',
      dialogVisible: false,
      entityInfos: [],
      entityTypes: [],
      avatarFiles: [],
      fileList: [
        {
          name: 'attachment.jpeg',
          url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
        },
        {
          name: 'attachment2.jpeg',
          url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
        },
      ],
      tranche: [],
      association: [],
    };
  },
  async created() {
    console.log('formdata print hobeeeee' + this.formData);
    await this.associationList();
    await this.getTranche();
  },
  methods: {
    async associationList() {
      await ApiService.get('entity/list')
        .then((response) => {
          this.association = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranche() {
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranche = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
});
